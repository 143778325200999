<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <!--<span class="text-muted font-weight-bold mr-2">2020©</span>
        <a
          href="http://keenthemes.com/metronic"
          target="_blank"
          class="text-dark-75 text-hover-primary"
          >Keenthemes</a
        >-->
      </div>
      <!--end::Copyright-->
      <!--begin::Nav-->
      <div class="nav nav-dark order-1 order-md-2">
        <router-link class="nav-link pr-3 pl-0" :to="{ name: 'data' }">
          Data &amp; Baseline
        </router-link>
        <router-link class="nav-link px-3" :to="{ name: 'rules' }">
          Evaluation Protocol
        </router-link>
        <a
          href="https://datasets-benchmarks-proceedings.neurips.cc/paper/2021/hash/069059b7ef840f0c74a814ec9237b6ec-Abstract-round2.html"
          target="_blank"
          class="nav-link px-3"
          >Publication</a
        >
        <a
          href="https://github.com/due-benchmark"
          target="_blank"
          class="nav-link px-3"
          >GitHub</a
        >
        <a href="mailto:lukasz.borchmann@applica.ai" class="nav-link pl-3 pr-0">
          Contact
        </a>
      </div>
      <!--end::Nav-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
