<template>
  <div>
    <router-link
      class="btn btn-icon btn-clean btn-lg w-40px h-40px"
      v-if="
        currentUser && (!currentUser.username || !currentUser.username.length)
      "
      :to="{ name: 'login' }"
      id="kt_quick_user_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title=""
      v-b-tooltip.hover.right="'Account'"
    >
      <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="/media/svg/icons/General/User.svg" />
          <!--end::Svg Icon-->
        </span>
        <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
    </router-link>
    <div
      class="btn btn-icon btn-clean btn-lg w-40px h-40px"
      v-if="currentUser && currentUser.username && currentUser.username.length"
      @click="onLogout"
      id="kt_quick_user_toggle"
      data-toggle="tooltip"
      data-placement="right"
      data-container="body"
      data-boundary="window"
      title=""
      v-b-tooltip.hover.right="'Logout'"
    >
      <span class="symbol symbol-30 symbol-lg-40">
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon-->
          <inline-svg src="/media/svg/icons/Home/Door-open.svg" />
          <!--end::Svg Icon-->
        </span>
        <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "KTQuickUser",
  mounted() {
    // Init Quick User Panel
    // KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapState({
      user: state => state.auth.user
    })
  }
};
</script>
